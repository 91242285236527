import React from "react"

const Slide = React.forwardRef(({ fullscreen, column, columnRight, padded, ...props }, ref) => {
    return (
        <div
            ref={ref}
            {...props}
            className={`slide ${fullscreen ? "at-least-fullscreen" : ""} ${column ? "column" : ""} ${columnRight ? "column-right" : ""} ${padded ? "padding-vertical-large" : ""} ${props.className || ""}`}
        >
            {props.children}
        </div>
    )
})

export default Slide