import React from "react"

import { PhoneBackButton } from "../Navigation"

import Img from "gatsby-image"

import Slide from "../Slide"
import IconLink from "../IconLink"

import "./ProjectDetails.scss"

const readableUrl = (url) => url.replace(/(^\w+:|^)\/\//, '')

const Links = ({ links }) => {
    const names = Object.keys(links)
    const one = names.length === 1
    return (
        <>
            { names.map(name => (
                <IconLink
                    key={name}
                    name={name}
                    label={one ? readableUrl(links[name]) : null}
                    href={links[name]}
                    size={one ? 32 : 24}
                    />
            )) }
        </>
    )
}

const ProjectDetails = ({ project }) => {

    return (
        <Slide column className="flex flex-column project-details">
            <PhoneBackButton />
            <h1>{ project.name }</h1>
            { project.links ? (
                <div className="flex flex-row project-links-container justify-content-start margin-top margin-bottom">
                    <Links links={ project.links } />
                </div>
            ) : null }
            <div className="relative flex flex-1 flex-row phone-flex-column">
                <div className="flex-1">
                    <Img
                        className="image"
                        fluid={project.media.fluid}
                        objectFit="cover"
                    />
                </div>
                <div className="project-tags-container margin-left align-self-flex-end">
                    { project.tags ? project.tags.map(tag => (
                        <span
                            className="project-tag"
                            key={tag}
                        >
                            #{ tag }
                        </span>
                    )) : null }
                </div>
            </div>
            <div className="text-justify" dangerouslySetInnerHTML={{ __html: project.contentNode.childMarkdownRemark.html }} />
        </Slide>
    )
}

export default ProjectDetails