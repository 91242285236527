import React, { useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import useScrollSpy from "react-use-scrollspy"

import { isBrowser } from "../util"

import "@animated-burgers/burger-slide/dist/styles.css"
import "../styles/reset-button.css"

import SEO from "../components/SEO"

import IntroductionSlide from "../components/slides/IntroductionSlide"
import MainSlide from "../components/slides/MainSlide"
import ProjectsSlide from "../components/slides/ProjectsSlide"
import GamesSlide from "../components/slides/GamesSlide"
import DesignSlide from "../components/slides/DesignSlide"
import OtherSlide from "../components/slides/OtherSlide"

import ReactModal from "react-modal"

import Navigation from "../components/Navigation"

import ThemeToggle from "../components/ThemeToggle"

import useGlobalState from "../state/globalState"

import Project from "../Project"

// styles
import "../styles/variables.css"
import "../styles/index.scss"
import "../styles/modal.css"

ReactModal.setAppElement('#___gatsby')



const IndexPage = () => {
    const data = useStaticQuery(graphql`
        query {
            allDatoCmsProject(filter: { page: { name: { eq: "Projects" } } }, sort: { fields: [date], order: DESC }) {
                edges {
                    node {
                        name
                        tags
                        date
                        media {
                            fluid(imgixParams: { fm: "jpg", auto: "compress" }) {
                              ...GatsbyDatoCmsFluid
                            }
                        }
                        contentNode {
                            childMarkdownRemark {
                                html
                                timeToRead
                            }
                        }
                        links
                    }
                }
            }
            allDatoCmsPage(filter: { name: { eq: "Projects" } }) {
                edges {
                    node {
                        contentNode {
                            childMarkdownRemark {
                                html
                                timeToRead
                            }
                        }
                    }
                }
            }
        }
    `)

    const projects = data.allDatoCmsProject.edges.map(obj => new Project(obj.node))

    const refs = {
        introduction: useRef(),
        main: useRef(),
        projects: useRef(),
        games: useRef(),
        design: useRef(),
        other: useRef()
    }

    const sectionElementRefs = [
        refs.introduction,
        refs.main,
        refs.projects,
        refs.games,
        refs.design,
        refs.other
    ]

    const getActiveSectionFromName = (name) => {
        const index = Object.keys(refs).indexOf(name)
        return (index > -1) ? index : getActiveSectionFromName("main")
    }

    const activeSectionDefault = getActiveSectionFromName(
        (isBrowser() && window.location && window.location.hash !== "") ? window.location.hash.substring(1) : "main"
    )

    const activeRef = sectionElementRefs[useScrollSpy({
        activeSectionDefault,
        sectionElementRefs,
        offsetPx: isBrowser() ? (-window.innerHeight / 2) : 0
    })]

    let title = null;
    switch (activeRef) {
        case refs.introduction: title = "introduction"; break
        case refs.projects: title = "projects"; break
        case refs.games: title = "games"; break
        case refs.design: title = "design"; break
        case refs.other: title = "other"; break
        default: title = "ulydev"; break
    }
    const [currentTitle, setTitle] = useGlobalState("title")
    if (title !== currentTitle) setTitle(title)

    const [isAnyModalOpen, ] = useGlobalState("isAnyModalOpen")

    //useEffect(() => setupMainPage(), [])
    return (
        <>
            <SEO />
            <Navigation projects={projects} />
            <ThemeToggle />
            <div id="slides" className={isAnyModalOpen ? "modal-external--open" : "modal-external--close"}>
                <IntroductionSlide ref={refs.introduction} />
                <MainSlide ref={refs.main} />
                <ProjectsSlide ref={refs.projects} projects={projects} />
                <GamesSlide ref={refs.games} show={!isAnyModalOpen && activeRef === refs.games} />
                <DesignSlide ref={refs.design} show={!isAnyModalOpen && activeRef === refs.design} />
                <OtherSlide ref={refs.other} show={!isAnyModalOpen && activeRef === refs.other} />
            </div>
        </>
    )
}

export default IndexPage
