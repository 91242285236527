import React from "react"

import CustomModal from "./CustomModal"
import ProjectDetails from "./slides/ProjectDetails"

const ProjectModal = ({ isOpen, project }) => {
    return (
        <CustomModal
            isOpen={isOpen}
            contentLabel={ project.name }
        >
            <ProjectDetails project={project} />
        </CustomModal>
    )
}

export default ProjectModal