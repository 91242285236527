import React, { useState, useEffect } from "react"

import Burger from "@animated-burgers/burger-slide"

import useGlobalState from "../state/globalState"

import titles, { main, back, projects as projectsTitle } from "../titles"

import { underscorize } from "../util"

import "./Navigation.scss"

import Icon from "./Icon"

const PhoneBackButton = () => {

    const [isAnyModalOpen, setIsAnyModalOpen] = useGlobalState('isAnyModalOpen')

    return (
        <div className="phone-only phone-back-button">
            <span className="title"><wbr/></span>
            <a
                href={undefined}
                className={`absolute cursor title ${!isAnyModalOpen ? 'hide-title' : ''}`}
                onClick={() => setIsAnyModalOpen(false)}
            >
                <Icon name="back" size={48} />
            </a>
        </div>
    )
}

const Title = ({ title: currentTitle }) => {

    const [isAnyModalOpen, setIsAnyModalOpen] = useGlobalState('isAnyModalOpen')

    const show = title => (isAnyModalOpen && title === back) || (!isAnyModalOpen && title === currentTitle)

    const [forceHide, setForceHide] = useState(true)
    useEffect(() => { setTimeout(() => setForceHide(false), 1000) }, [])

    return (
        <div id="title" className="relative phone-except">
            <span className="title"><wbr/></span>
            {titles.map(title => (
                <a
                    key={title}
                    className={`absolute cursor title ${(!show(title) || forceHide) ? 'hide-title' : ''}`}
                    href={title !== back ? "#main" : undefined}
                    onClick={title === back ? (() => setIsAnyModalOpen(false)) : undefined}
                >
                    {title === back ? <Icon name="back" size={48} /> : title}
                </a>
            ))}
        </div>
    )
}
  
const Menu = ({ title, className }) => {
    const [isOpen, setOpen] = useState(false)
    const close = () => {
        setOpen(false)
        document.removeEventListener("click", close)
    }
    const open = () => {
        setOpen(true)
        document.addEventListener("click", close)
    }

    const [isAnyModalOpen, ] = useGlobalState('isAnyModalOpen')
    const hide = isAnyModalOpen || (title !== main)
    if (hide && isOpen) setOpen(false)

    const [forceHide, setForceHide] = useState(true)
    useEffect(() => { setTimeout(() => setForceHide(false), 2000) }, [])

    return (
        <nav className={`menu to-front flex flex-row ${className || ""}`}>
            <Burger isOpen={isOpen} onClick={() => !isOpen ? open() : close()} className={`burger ${hide ? "hide" : ""} ${forceHide ? "force-hide" : ""}`} />
            <ul className={`to-front ${!isOpen ? 'hide-menu' : ''}`}>
                <li><a href="#introduction">introduction</a></li>
                <li><a href="#projects">projects</a></li>
                <li><a href="#games">games</a></li>
                <li><a href="#design">design</a></li>
                <li><a href="#other">other</a></li>
            </ul>
        </nav>
    )
}

const ProjectsMenu = ({ show, projects, activeProject }) => {

    return (
        <nav id="projects-menu" className={`phone-except to-front flex flex-row ${!show ? 'hide-projects-menu' : ''}`}>
            <ul>
                {projects.map(project => (
                    <li
                        key={project.name}
                        className={(activeProject && activeProject.equals(project)) ? "active-project" : ""}
                    >
                        <a href={`#${underscorize(project.name)}`}>{ project.name }</a>
                    </li>
                ))}
            </ul>
        </nav>
    )
}

const Navigation = ({ projects }) => {

    const [isAnyModalOpen, ] = useGlobalState('isAnyModalOpen')

    const [title, ] = useGlobalState("title")
    const showProjects = (title === projectsTitle) && !isAnyModalOpen

    const [project, ] = useGlobalState("project")

    return (
        <div id="top-left-container" className="phone-except">
            <Title title={title} />
            <Menu title={title} className="phone-except" />
            <ProjectsMenu show={showProjects} projects={projects} activeProject={project} />
        </div>
    )
}

export default Navigation
export { Menu, PhoneBackButton }