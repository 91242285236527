import React, { useState } from "react"
import Img from "gatsby-image"

import useGlobalState from "../../state/globalState"

import ProjectModal from "../ProjectModal"

const GameThumbnail = ({ project }) => {

    const [isModalOpen, setModalOpen] = useState(false)

    const [isAnyModalOpen, setAnyModalOpen] = useGlobalState('isAnyModalOpen')

    if (isModalOpen && !isAnyModalOpen) setModalOpen(false) // react to global change

    const setOpen = (open) => {
        setAnyModalOpen(open)
        setModalOpen(open)
    }

    return (
        <div key={project.name}>
            { [true, false].map(show => (
                <React.Fragment key={`${show}`}>
                    <div className="project-thumbnail-container">
                        <button
                            aria-label={`Open project ${project.name}`}
                            className={`relative div project-thumbnail ${show ? "cursor" : "hide"}`}
                            onClick={() => setOpen(true)}
                        >
                            <Img
                                className="image"
                                fluid={project.thumbnail.fluid}
                                objectFit="contain"
                            />
                            { show ? (
                                <div className="project-tooltip-details flex flex-column phone-except">
                                    <h3>{ project.name }</h3>
                                </div>
                            ) : null }
                        </button>
                    </div>
                    { show ? <ProjectModal isOpen={isModalOpen} project={project} /> : null }
                </React.Fragment>
            )) }
        </div>
    )
}

export default GameThumbnail