export const back = "back"
export const introduction = "introduction"
export const main = "ulydev"
export const projects = "projects"
export const games = "games"
export const design = "design"
export const other = "other"

const titles = [
    back, // special title
    introduction,
    main,
    projects,
    games,
    design,
    other
]
export default titles