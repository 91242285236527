import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Slide from "../Slide"

import GameThumbnail from "./GameThumbnail"

import Project from "../../Project"

import "./GamesSlide.scss"

const GamesSlide = React.forwardRef(({ show }, ref) => {
    const data = useStaticQuery(graphql`
        query {
            allDatoCmsProject(filter: { page: { name: { eq: "Games" } } }, sort: { fields: [date], order: DESC }) {
                edges {
                    node {
                        name
                        tags
                        date
                        thumbnail {
                            fluid(imgixParams: { fm: "jpg", auto: "compress" }) {
                              ...GatsbyDatoCmsFluid
                            }
                        }
                        media {
                            fluid(imgixParams: { fm: "jpg", auto: "compress" }) {
                              ...GatsbyDatoCmsFluid
                            }
                        }
                        contentNode {
                            childMarkdownRemark {
                                html
                                timeToRead
                            }
                        }
                        links
                    }
                }
            }
            allDatoCmsPage(filter: { name: { eq: "Games" } }) {
                edges {
                    node {
                        contentNode {
                            childMarkdownRemark {
                                html
                                timeToRead
                            }
                        }
                    }
                }
            }
        }
    `)

    const projects = data.allDatoCmsProject.edges.map(obj => new Project(obj.node))

    const gamesPage = data.allDatoCmsPage.edges[0].node

    return (
        <Slide
            columnRight
            padded
            ref={ref}
            id="games"
            className="relative flex flex-column"
        >
            <h1 className="title phone-only"><a href="#main">games</a></h1>
            <div className={`tooltip-introduction to-front ${show ? "" : "hide"} phone-always-show`}>
                <div dangerouslySetInnerHTML={{ __html: gamesPage.contentNode.childMarkdownRemark.html }} />
            </div>
            <div className="image-grid fill-width">
                { projects.map(project => <GameThumbnail key={project.name} project={project} />) }
            </div>
        </Slide>
    )
})

export default GamesSlide