import React from "react"

import Icon from "./Icon"

import "./ScrollCue.css"

const ScrollCue = ({ direction, ...props }) => {
    return (
        <div {...props} className={`scroll-cue-container scroll-${direction} ${props.className || ""}`}>
            {/*
            <svg
                className="animated bounce scroll-cue"
                x="0px" y="0px"
                viewBox="0 0 86.001 86.001"
            >
                <g>
                    <path d="M5.907,21.004c-1.352-1.338-3.542-1.338-4.894,0c-1.35,1.336-1.352,3.506,0,4.844l39.54,39.15
                        c1.352,1.338,3.542,1.338,4.894,0l39.54-39.15c1.351-1.338,1.352-3.506,0-4.844c-1.352-1.338-3.542-1.338-4.894-0.002L43,56.707
                        L5.907,21.004z"/>
                </g>
            </svg>
            */}
            <Icon
                className="animated bounce scroll-cue"
                name="scrollcue"
            />
        </div>
    )
}

export default ScrollCue