import React from "react"

import "./HyperDOS.css"

const HyperDOS = ({ className }) => {
    return (
        <div id="hyper-dos-container" className={`relative ${className}`}>
            <svg viewBox="-265 -150 530 300">
                <polyline
                    id="border-top-left"
                    points="
                        -140 -120
                        -140 -130
                        -130 -130
                    "
                    style={{ fill: "transparent", stroke: "inherit", strokeWidth: 2 }}
                />
                <polyline
                    id="border-bottom-right"
                    points="
                        140 120
                        140 130
                        130 130
                    "
                    style={{ fill: "transparent", stroke: "inherit", strokeWidth: 2 }}
                />
                <polygon
                    id="center-square"
                    points="
                        -25, -25
                        25, -25
                        25, 25
                        -25, 25
                    "
                    style={{ fill: "inherit" }}
                />
                <polygon
                    id="first-border"
                    points="
                        -30, -30
                        30, -30
                        30, 30
                        -30, 30
                    "
                    style={{ fill: "transparent", stroke: "inherit", strokeWidth: 2 }}
                />
                <polygon
                    id="second-border"
                    points="
                        -35, -35
                        35, -35
                        35, 35
                        -35, 35
                    "
                    style={{ fill: "transparent", stroke: "inherit", strokeWidth: 2 }}
                />
                <polygon
                    id="triangle"
                    points="
                        0 -100
                        -87 50
                        87 50
                    "
                    style={{ fill: "transparent", stroke: "inherit", strokeWidth: 3 }} 
                />
                <polygon
                    id="pentagon"
                    points="
                        0 -140
                        -133 -43
                        -82 113
                        82 113
                        133 -43
                    "
                    style={{ fill: "transparent", stroke: "inherit", strokeWidth: 3 }}
                />
            </svg>
        </div>
    )
}

export default HyperDOS