import React, { useState } from "react"

import "./ThemeToggle.css"

const ThemeToggle = () => {

    const [light, setLight] = useState(false)

    const toggle = (e) => {
        setLight(!light)
        document.documentElement.setAttribute('data-theme', !light ? "light" : "dark")
    }

    return (
        <div className="theme-toggle">
            <label className="switch">
                <input aria-label="Toggle color theme" type="checkbox" onChange={toggle}/>
                <span className="slider round"></span>
            </label>
        </div>
    )
}

export default ThemeToggle