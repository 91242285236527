import React, { useState } from "react"

import Slide from "../Slide"
import ProjectModal from "../ProjectModal"

import { underscorize } from "../../util"

import useGlobalState from "../../state/globalState"

import "./ProjectSlide.scss"

import TheCurve from "../projects/TheCurve"
import Helpedia from "../projects/Helpedia"
import BOOOMING from "../projects/BOOOMING"
import HyperDOS from "../projects/HyperDOS"

const getProjectPreview = (project) => {
    switch (project.name) {
        case "The Curve": return <TheCurve />
        case "Helpedia": return <Helpedia />
        case "BOOOMING": return <BOOOMING />
        case "Hyper DOS": return <HyperDOS />
        default: return null
    }
}

const ProjectSlide = React.forwardRef(({ project, ...props }, ref) => {
    const [isModalOpen, setModalOpen] = useState(false)

    const [isAnyModalOpen, setAnyModalOpen] = useGlobalState('isAnyModalOpen')

    if (isModalOpen && !isAnyModalOpen) setModalOpen(false) // react to global change

    const setOpen = (open) => {
        setAnyModalOpen(open)
        setModalOpen(open)
    }

    return (
        <Slide
            ref={ref}
            id={underscorize(project.name)}
            className="flex align-items-center project-slide scroll-snap-center"
            fullscreen
            column
        >
            <div className="relative flex flex-1 flex-row phone-flex-column">
                <span className="title phone-only cursor" onClick={() => setOpen(true)}>{ project.name }</span>
                <div className="margin-left project-tags-container align-self-flex-end">
                    { project.tags ? project.tags.map(tag => (
                        <span
                            className="project-tag"
                            key={tag}
                        >
                            #{ tag }
                        </span>
                    )) : null }
                </div>
                <button
                    aria-label={`Open project ${project.name}`}
                    className="flex-1 div cursor"
                    onClick={() => setOpen(true)}
                >
                    { getProjectPreview(project) }
                </button>
            </div>
            <ProjectModal isOpen={isModalOpen} project={project} />
        </Slide>
    )
})

export default ProjectSlide