import React from 'react'

import Slide from "../Slide"

import ShaderCanvas from "../ShaderCanvas"
import ScrollCue from "../ScrollCue"

import { Menu } from "../Navigation"

import { main } from "../../titles"

const MainSlide = React.forwardRef((props, ref) => {
    return (
        <>
            <Slide
                ref={ref}
                id="main"
                fullscreen
                className="relative flex flex-column align-items-center justify-content-center scroll-snap-center"
            >
                <div className="phone-only top-left">
                    <span className="title">{ main }</span>
                    <Menu title={main} />
                </div>
                <ShaderCanvas />
                <ScrollCue direction="up"/>
                <ScrollCue className="hide" direction="down"/>
            </Slide>
            {/* Navigate to main element as soon as it's loaded */}
            <div dangerouslySetInnerHTML={{ __html: `<script type="text/javascript">const hash = location ? (location.hash || "#main") : "#main"; window.scrollTo({ top: document.querySelector(hash).offsetTop, behavior: "auto" });</script>`}} />
        </>
    )
})

export default MainSlide