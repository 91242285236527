import React, { useRef } from "react"
import useScrollSpy from "react-use-scrollspy"

import { isBrowser } from "../../util"

import ProjectSlide from "./ProjectSlide"

import useGlobalState from "../../state/globalState"

const ProjectsSlide = React.forwardRef(({ projects }, ref) => {

    const sectionElementRefs = projects.map(() => useRef())

    const activeProject = projects[useScrollSpy({
        sectionElementRefs,
        offsetPx: isBrowser() ? (-window.innerHeight) : 0
    })]

    const [project, setProject] = useGlobalState("project")
    if (activeProject && !activeProject.equals(project)) setProject(activeProject)

    return (
        <div ref={ref} id="projects" className="relative">
            <span className="title phone-only"><a href="#main">projects</a></span>
            {projects.map((project, i) => (
                <ProjectSlide
                    ref={sectionElementRefs[i]}
                    key={project.name}
                    project={project} />
            ))}
        </div>
    )
})

export default ProjectsSlide