import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import "./BOOOMING.scss"

const BOOOMING = ({ className }) => {
    const images = useStaticQuery(graphql`
        query {
            logo: file(relativePath: { eq: "images/BOOOMING/logo.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            model: file(relativePath: { eq: "images/BOOOMING/model.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <div id="boooming-container" className={`relative fill-max ${className}`}>
            <div id="boooming-logo" className="fill-max">
                <Img fluid={images.logo.childImageSharp.fluid} />
            </div>
            <div id="boooming-model" className="fill-max">
                <Img fluid={images.model.childImageSharp.fluid} objectFit="contain" objectPosition="right center" />
            </div>
        </div>
    )
}

export default BOOOMING