import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Icon from "../Icon"

import Slide from "../Slide"

import Project from "../../Project"

import "./OtherSlide.scss"

const OtherSlide = React.forwardRef(({ show }, ref) => {
    const data = useStaticQuery(graphql`
        query {
            allDatoCmsProject(filter: { page: { name: { eq: "Other" } } }, sort: { fields: [date], order: DESC }) {
                edges {
                    node {
                        name
                        contentNode {
                            childMarkdownRemark {
                                html
                                timeToRead
                            }
                        }
                        links
                    }
                }
            }
            allDatoCmsPage(filter: { name: { eq: "Other" } }) {
                edges {
                    node {
                        contentNode {
                            childMarkdownRemark {
                                html
                                timeToRead
                            }
                        }
                    }
                }
            }
        }
    `)

    const projects = data.allDatoCmsProject.edges.map(obj => new Project(obj.node))

    const otherPage = data.allDatoCmsPage.edges[0].node

    return (
        <Slide
            columnRight
            padded
            ref={ref}
            id="other"
            className="relative flex flex-column"
        >
            <h1 className="title phone-only"><a href="#main">other</a></h1>
            <div className={`tooltip-introduction to-front ${show ? "" : "hide"} phone-always-show`}>
                <div dangerouslySetInnerHTML={{ __html: otherPage.contentNode.childMarkdownRemark.html }} />
            </div>
            <div className="fill-width">
                { projects.map(({ links, ...project }) => (
                    <div
                        key={project.name}
                        className="project-slide scroll-snap-center"
                    >
                        <a
                            className="flex flex-row align-items-center fit-content-height link"
                            href={Object.values(links)[0]}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <h3>{ project.name }</h3>
                            <Icon name={Object.keys(links)[0]} className="margin-left" size={24} />
                        </a>
                        <div dangerouslySetInnerHTML={{ __html: project.contentNode.childMarkdownRemark.html }} />
                    </div>
                )) }
            </div>
        </Slide>
    )
})

export default OtherSlide