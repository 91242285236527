import React, { useRef, useMemo } from 'react'
import { useFrame, useThree, useResource } from "react-three-fiber"

const ShaderAnimation = (props) => {

    const mesh = useRef()
    const [materialRef, material] = useResource()
  
    const { mouse } = useThree()
  
    const lerp = (a, b, k) => (a * (1-k) + b * k)
  
    useFrame((state, delta) => {
        mesh.current.rotation.y = lerp(mesh.current.rotation.y, -mouse.x / 2, 0.05)
        mesh.current.rotation.x = lerp(mesh.current.rotation.x,  mouse.y / 2, 0.05)
        materialRef.current.uniforms.time.value += delta
    })
  
    const rgbColor = [123, 193, 236].map(v => v * 0.75) // blue

    const uniforms = useMemo(() => ({
        time: {type: 'f', value: 0},
        color: {type: 'v3', value: rgbColor.map(v => v / 255)},
        mouse: {type: 'v2', value: mouse}
    }), []) // eslint-disable-line react-hooks/exhaustive-deps
  
    return (
        <>
            <shaderMaterial
                ref={materialRef}
                attach="material"
                vertexShader={`
                    uniform float time;
                    uniform vec2 mouse;
                    varying vec2 vUv;
                
                    void main() {
                        vUv = uv;
                        vec3 upDirection = vec3(0.2 * sin(position.y * 10.0 + time * 4.0), 0.2 * sin(position.x * 10.0 + time), 0.2 * sin(position.x * 10.0 + time * 4.0));
                        vec3 newPosition = position + normal * upDirection * 2.0;
                    
                        gl_Position = projectionMatrix * modelViewMatrix * vec4(newPosition, 1.0);
                    }
                `}
                fragmentShader={`
                    uniform float time;
                    uniform vec3 color;
                    varying vec2 vUv;
        
                    void main() {
                        vec3 newColor = color * mod(vUv.y + time * 0.5 + cos(time * 2.0) * 0.1, 1.0) * (2.0 + sin(vUv.x * 2.0 * 3.1415 + time * 1.0) * 1.0);
                        gl_FragColor = vec4(newColor, 1.0);
                    }
                `}
                wireframe={true}
                uniforms={uniforms}
            />
            {material && (
                <mesh
                    ref={mesh}
                    position={[0, 0, 0]}
                    rotation-y={mouse.x*10.0}
                    material={material}
                >
                    <torusGeometry
                        attach="geometry"
                        args={[2.2, 0.4, 8*4, 6*4]}
                    />
                </mesh>
            )}
        </>
    )
}

export default ShaderAnimation