import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Slide from "../Slide"

import Project from "../../Project"

import "./DesignSlide.scss"

const DesignSlide = React.forwardRef(({ show }, ref) => {
    const data = useStaticQuery(graphql`
        query {
            allDatoCmsProject(filter: { page: { name: { eq: "Design" } } }, sort: { fields: [date], order: DESC }) {
                edges {
                    node {
                        name
                        thumbnail {
                            fluid(imgixParams: { fm: "jpg", auto: "compress" }) {
                              ...GatsbyDatoCmsFluid
                            }
                        }
                        contentNode {
                            childMarkdownRemark {
                                html
                                timeToRead
                            }
                        }
                    }
                }
            }
            allDatoCmsPage(filter: { name: { eq: "Design" } }) {
                edges {
                    node {
                        contentNode {
                            childMarkdownRemark {
                                html
                                timeToRead
                            }
                        }
                    }
                }
            }
        }
    `)

    const projects = data.allDatoCmsProject.edges.map(obj => new Project(obj.node))

    const designPage = data.allDatoCmsPage.edges[0].node

    return (
        <Slide
            columnRight
            padded
            ref={ref}
            id="design"
            className="relative flex flex-column"
        >
            <h1 className="title phone-only"><a href="#main">design</a></h1>
            <div className={`tooltip-introduction to-front ${show ? "" : "hide"} phone-always-show`}>
                <div dangerouslySetInnerHTML={{ __html: designPage.contentNode.childMarkdownRemark.html }} />
            </div>
            <div className="image-grid fill-width">
                { projects.map(project => (
                    <div
                        key={project.name}
                        className="project-thumbnail"
                    >
                        <Img
                            className="image"
                            fluid={project.thumbnail.fluid}
                            objectFit="contain"
                        />
                        <div className="project-tooltip-details to-front phone-except">
                            { /* fake height */ }
                            <div className="hide margin-bottom-3" dangerouslySetInnerHTML={{ __html: designPage.contentNode.childMarkdownRemark.html }} />

                            <h3>{ project.name }</h3>
                            <div dangerouslySetInnerHTML={{ __html: project.contentNode.childMarkdownRemark.html }} />
                        </div>
                    </div>
                )) }
            </div>
        </Slide>
    )
})

export default DesignSlide