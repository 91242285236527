import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import "./Helpedia.css"

const TheCurve = ({ className }) => {
    const images = useStaticQuery(graphql`
        query {
            map: file(relativePath: { eq: "images/Helpedia/map.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <div id="helpedia-container" className={`relative flex justify-content-center align-items-center ${className}`}>
            <div id="helpedia-preview">
                <Img fluid={images.map.childImageSharp.fluid} />
            </div>
        </div>
    )
}

export default TheCurve