import React from "react"

import "./Icon.css"

import { ReactComponent as IconAndroid } from "../assets/icons/android.svg"
import { ReactComponent as IconApple } from "../assets/icons/apple.svg"
import { ReactComponent as IconGithub } from "../assets/icons/github.svg"
//import { ReactComponent as IconInformation } from "../assets/icons/information.svg"
import { ReactComponent as IconIphone } from "../assets/icons/iphone.svg"
import { ReactComponent as IconLink } from "../assets/icons/link.svg"
import { ReactComponent as IconWindows } from "../assets/icons/windows.svg"
import { ReactComponent as IconBack } from "../assets/icons/back.svg"
import { ReactComponent as IconMail } from "../assets/icons/mail.svg"
import { ReactComponent as IconScrollcue } from "../assets/icons/scrollcue.svg"
import { ReactComponent as IconLinkedin } from "../assets/icons/linkedin.svg"
import { ReactComponent as IconInstagram } from "../assets/icons/instagram.svg"
import { ReactComponent as IconResume } from "../assets/icons/resume.svg"

const icons = {
    mac: IconApple,
    windows: IconWindows,
    ios: IconIphone,
    android: IconAndroid,
    github: IconGithub,
    information: IconLink,//IconInformation,
    link: IconLink,
    back: IconBack,
    mail: IconMail,
    scrollcue: IconScrollcue,
    linkedin: IconLinkedin,
    resume: IconResume,
    instagram: IconInstagram
}

const Icon = ({ name, color = "currentColor", size, className, ...props }) => {
    const NamedIcon = icons[name]

    return (
        <NamedIcon color={color} width={size} height={size} className={`icon ${className || ""}`} {...props} />
    )
}

export default Icon