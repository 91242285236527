import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Slide from "../Slide"

import IconLink from "../IconLink"

import "./IntroductionSlide.scss"

const IntroductionSlide = React.forwardRef((props, ref) => {

    const data = useStaticQuery(graphql`
        query {
            datoCmsAboutPage {
                contentNode {
                    childMarkdownRemark {
                        html
                        timeToRead
                    }
                }
            }
            me: file(relativePath: { eq: "images/me.jpg" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <Slide
            ref={ref}
            id="introduction"
            className="flex flex-column scroll-snap-start"
            column
        >
            <h1 className="title phone-only"><a href="#main">introduction</a></h1>
            <div className="flex flex-column fit-content-width">
                <h1 className="name">Ulysse Ramage</h1>
                <div className="introduction-links-container flex flex-row justify-content-center align-items-start phone-flex-column">
                    <IconLink name="github" size={20} href="https://github.com/ulydev" label="ulydev" />
                    { /* <IconLink name="linkedin" size={20} href="https://linkedin.com/in/ulydev" label="in/ulydev" /> */ }
                    <IconLink name="mail" size={20} href="mailto:hi@uly.dev" label="hi@uly.dev" />
                </div>
            </div>
            <Img className="image-me" fluid={data.me.childImageSharp.fluid} />
            <div className="text-justify introduction-content" dangerouslySetInnerHTML={{ __html: data.datoCmsAboutPage.contentNode.childMarkdownRemark.html }} />
        </Slide>
    )
})

export default IntroductionSlide