import React from "react"

import Modal from "react-modal"

const CustomModal = React.forwardRef((props, ref) => (
    <Modal
        {...props}
        ref={ref}
        closeTimeoutMS={1000}
        className={{
            base: "modal-content",
            afterOpen: "modal-content--after-open",
            beforeClose: "modal-content--before-close"
        }}
        overlayClassName={{
            base: "modal-overlay",
            afterOpen: "modal-overlay--after-open",
            beforeClose: "modal-overlay--before-close"
        }}
    />
))

export default CustomModal