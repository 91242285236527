import React from "react"

import Icon from "./Icon"

import "./IconLink.css"

const IconLink = ({ href, name, size, label, ...props }) => {
    return (
        <a
            className="flex flex-row align-items-center margin-after-each-horizontal link icon-link"
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            {...props}
        >
            <Icon
                className="display-inline-block fill-height"
                name={name}
                size={size}
            />
            { label ? (
                <span className="project-link-one">{ label }</span>
            ) : null }
        </a>
    )
}

export default IconLink