import React from "react"

import { ReactComponent as SvgDJ } from "../../assets/images/TheCurve/dj.svg"

import "./TheCurve.css"

const Heart = ({ color, style, className }) => {
    return (
        <svg
            className={`heart absolute ${className}`}
            color={color}
            style={style}
            width="64"
            height="64"
            viewBox="0 0 340 340"
        >
            <g transform="rotate(225,150,121)">
                <path 
                    d="M0 200 v-200 h200 
                    a100,100 90 0,1 0,200
                    a100,100 90 0,1 -200,0
                    z" />
            </g>
        </svg>
    )
}

const TheCurve = ({ className }) => {
    return (
        <div id="the-curve-container" className={`relative flex justify-content-center align-items-center ${className}`}>
            <Heart className="left" style={{ left: 140, top: 'calc(50% - 80px)' }} />
            <Heart className="right" style={{ right: 80, top: 'calc(50% - 30px)' }} />
            <SvgDJ width="100%" color="var(--accent-color)" />
        </div>
    )
}

export default TheCurve