import React, { useState } from "react"
import { Canvas } from "react-three-fiber"

import { isBrowser } from "../util"

import ShaderAnimation from "./ShaderAnimation"

const ShaderCanvas = () => {

    const [loaded, setLoaded] = useState(false)

    return (
        <div id="canvas-container" className={loaded ? "loaded" : ""}>
            <Canvas
                pixelRatio={isBrowser() ? window.devicePixelRatio : 1}
                onCreated={() => setLoaded(true)}
            >
                <ShaderAnimation />
            </Canvas>
        </div>
    )
}

export default ShaderCanvas